import React, { useEffect, useState } from 'react'
// import PlanModal from './PlanModal';
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { plans } from '../../../redux/actions/plans.action';
import { RiAwardFill } from "react-icons/ri";
import limitedofferimage from "../../../assets/OFF.webp";
// import { basic, standard, premium, yearlybasic, yearlystandard, yearlypremium } from '../../Home/Plans/PlanData';

function Plans({ currentplan }) {
    //redux
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useSelector((state) => state.themeReducer.theme);
    const isAuthenticated = useSelector((state) => state.loginReducer.token);
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress } = useSelector((state) => state.userDataReducer.userData || {})
    const planData = useSelector((state) => state.planReducer.planData);
    
    const basic = [planData.basic || {}];
    const premium = [planData.premium || {}];
    const standard = [planData.standard || {}];
    const yearlybasic = [planData.yearlybasic || {}];
    const yearlystandard = [planData.yearlystandard || {}];
    const yearlypremium = [planData.yearlypremium || {}];
    
    //state
    const [currentViewMore, setCurrentViewMore] = useState('');
    const [isViewMore, setIsViewMore] = useState(false);


    useEffect(() => {
        dispatch(plans({ "email": email }));
    }, []);

    const handleViewmore = (plan) => {

        if (currentViewMore === plan[0].planname && isViewMore) {
            setIsViewMore(false);
            setCurrentViewMore("");
        }
        else {
            setCurrentViewMore(plan[0].planname);
            setIsViewMore(true);
        }
    };

    const getPaddingStyle = (plan) => {
        return plan.length > 0 && plan[0].plansubscription === "Yearly" ? { padding: "1rem 1rem 1.5rem 1rem" } : {};
    };

    const handleBuyPlan = (plan) => {
        if (isAuthenticated) {
            navigate('/frontend/plan-summary', { state: { selectedPlan: plan, FreeTrial: false } });
        }
        else {
            navigate('/frontend/login', { state: { from: '/plans', intendedPath: '/frontend/plan-summary', selectedPlan: plan, FreeTrial: false } });
        }
    }

    const handlePlanText = (plan) => {
        if (plan[0].purchased === "yes") {
            return "Current Plan";
        } else if (plan[0].purchased === "Upgrade") {
            return "Upgrade";
        } else {
            return "Change Plan";
        }
    }

    const handleCurrrentPlan = (plan) => {
        if (plan[0].purchased == "yes") {
            return "Current Plan"
        }
        else if (plan[0].purchased == "Upgrade") {
            handleBuyPlan(plan)
        }
        else {
            alert('Kindly Contact support team to change your current plan')
        }
    }

    return (
        <div className={`plans-section`} >
            {
                currentplan == "Monthly" ?
                    <>
                        <div className='plan'>
                            <div className='plan-header blue '>
                                <h2 className='plan-name'>
                                    <span className='basic-badge'><RiAwardFill /></span>
                                    <span className='plan-title'>{basic[0]?.planname}</span>
                                </h2>
                                {
                                    basic[0].previousprice != "" &&
                                    <>
                                        <span className='plan-prices'>&#8377;{basic[0]?.previousprice}</span>
                                    </>
                                }
                                <h3 className='plan-price'>&#8377;{basic[0]?.monthlyprice}</h3>
                            </div>
                            <div className='plan-body' style={getPaddingStyle(basic)}>
                                <div className='first-container'>
                                    <span className='feature-header'>Features:</span>
                                    <span className='features-title'>(HRM Basic Features)</span>
                                </div>
                                <div className='second-container'>
                                    <ul>
                                        {
                                            basic[0]?.featureslist?.slice(0, 4).map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <span className='core-feature'>Core Features</span>
                                    <ul>
                                        {
                                            basic[0]?.featureslist?.slice(4, 7).map((item, index) => {

                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                        {currentViewMore === basic[0].planname && isViewMore && basic[0].featureslist.slice(7).map((item, index) => (
                                            <li key={index}>
                                                <span><FaCheckCircle /></span>
                                                <span> {item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='third-container'>
                                    <span className='plan-viewmore' onClick={() => handleViewmore(basic)}>
                                        {currentViewMore === basic[0].planname && isViewMore ? 'view less' : 'view more'}
                                    </span>
                                </div>
                                <div className='fourth-container'>
                                    {basic[0]?.purchased == "" ?
                                        // <div className='prduct-button--container'>
                                        // <div>
                                        //     <button className='commonBtn purchased'><a href="">Free Trial</a></button>
                                        // </div>
                                        // <div>
                                        <button className="commonBtn" onClick={() => handleBuyPlan(basic)}>Buy Plan</button>
                                        // </div>

                                        // </div>
                                        :
                                        <button className={`commonBtn plan-btn ${handlePlanText(basic) == "Current Plan" ? "purchased" : ""}`} onClick={() => handleCurrrentPlan(basic)}>{handlePlanText(basic)}</button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='plan'>
                            <div className='plan-header blue'>
                                <h2 className='plan-name'>
                                    <span className='standard-badge'><RiAwardFill /></span>
                                    <span className='plan-title'>{standard[0].planname}</span>
                                </h2>
                                {
                                    standard[0].previousprice != "" &&
                                    <>
                                        <span className='plan-prices'>&#8377;{standard[0].previousprice}</span>
                                    </>
                                }
                                <h3 className='plan-price'>&#8377;{standard[0].monthlyprice}</h3>
                            </div>
                            <div className='plan-body' style={getPaddingStyle(standard)}>
                                <div className='first-container'>
                                    <span className='feature-header'>Features:</span>
                                    <span className='features-title'>(HRM Standard + KMS)</span>
                                </div>
                                <div className='second-container'>
                                    <ul>
                                        {
                                            standard[0]?.featureslist?.slice(0, 4).map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <span className='core-feature'>Everything in Basic +</span>
                                    <ul>
                                        {
                                            standard[0]?.featureslist?.slice(4, 7).map((item, index) => {

                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                        {currentViewMore === standard[0].planname && isViewMore && standard[0].featureslist.slice(7).map((item, index) => (
                                            <li key={index}>
                                                <span><FaCheckCircle /></span>
                                                <span> {item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='third-container'>
                                    <span className='plan-viewmore' style={{ marginTop: "0rem" }} onClick={() => handleViewmore(standard)}>
                                        {currentViewMore === standard[0].planname && isViewMore ? 'view less' : 'view more'}
                                    </span>
                                </div>
                                <div className='fourth-container'>
                                    {standard[0].purchased == "" ?
                                        // <div className='prduct-button--container'>
                                        //     <div>
                                        //         <button className='commonBtn purchased'><a href="">Free Trial</a></button>
                                        //     </div>
                                        //     <div>
                                        <button className="commonBtn" onClick={() => handleBuyPlan(standard)}>Buy Plan</button>
                                        //     </div>
                                        // </div>
                                        :
                                        <button className={`commonBtn plan-btn ${handlePlanText(standard) == "Current Plan" ? "purchased" : ""}`} onClick={() => handleCurrrentPlan(standard)}>{handlePlanText(standard)}</button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='plan'>
                            <div className='plan-header blue'>
                                <h2 className='plan-name'>
                                    <span className='premium-badge'><RiAwardFill /></span>
                                    <span className='plan-title'>{premium[0].planname}</span>
                                </h2>
                                {
                                    premium[0].previousprice != "" &&
                                    <>
                                        <span className='plan-prices'>&#8377;{premium[0].previousprice}</span>
                                    </>

                                }
                                <h3 className='plan-price'>&#8377;{premium[0].monthlyprice}</h3>

                            </div>
                            <div className='plan-body' style={getPaddingStyle(premium)}>
                                <div className='first-container'>
                                    <span className='feature-header'>Features:</span>
                                    <span className='features-title'>(HRM Advanced + KMS+ LMS)</span>
                                </div>
                                <div className='second-container'>
                                    <ul>
                                        {
                                            premium[0]?.featureslist?.slice(0, 4).map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <span className='core-feature'>Everything in Standard +</span>
                                    <ul>
                                        {
                                            premium[0]?.featureslist?.slice(4, 7).map((item, index) => {

                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                        {currentViewMore === premium[0].planname && isViewMore && premium[0].featureslist.slice(7).map((item, index) => (
                                            <li key={index}>
                                                <span><FaCheckCircle /></span>
                                                <span> {item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='third-container'>
                                    <span className='plan-viewmore' style={{ marginTop: "0rem" }} onClick={() => handleViewmore(premium)}>
                                        {currentViewMore === premium[0].planname && isViewMore ? 'view less' : 'view more'}
                                    </span>
                                </div>
                                <div className='fourth-container'>
                                    {premium[0].purchased === "" ?
                                        // <div className='prduct-button--container'>
                                        //     <div>
                                        //         <button className='commonBtn purchased'><a href="">Free Trial</a></button>
                                        //     </div>
                                        // <div>
                                        <button className="commonBtn" onClick={() => handleBuyPlan(premium)}>Buy Plan</button>
                                        // </div>
                                        // </div>
                                        : <button className={`commonBtn plan-btn ${handlePlanText(premium) == "Current Plan" ? "purchased" : ""}`} onClick={() => handleCurrrentPlan(premium)}>{handlePlanText(premium)}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='plan'>
                            <img src={limitedofferimage} alt="limited period offer" className='discount-offer' />
                            <div className='plan-header blue '>
                                <h2 className='plan-name'>
                                    <span className='basic-badge'><RiAwardFill /></span>
                                    <span className='plan-title'>{yearlybasic[0].planname}</span>
                                </h2>
                                {
                                    yearlybasic[0].previousprice != "" &&
                                    <>
                                        <span className='plan-prices'>&#8377;{yearlybasic[0].previousprice}</span>
                                    </>
                                }
                                <h3 className='plan-price'>&#8377;{yearlybasic[0].yearlyprice}</h3>
                            </div>
                            <div className='plan-body'>
                                <div className='first-container'>
                                    <span className='feature-header'>Features:</span>
                                    <span className='features-title'>(HRM Basic Features)</span>
                                </div>
                                <div className='second-container'>
                                    <ul>
                                        {
                                            yearlybasic[0].featureslist?.slice(0, 4).map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <span className='core-feature'>Core Features</span>
                                    <ul>
                                        {
                                            yearlybasic[0]?.featureslist
                                                ?.slice(4, 7)?.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <span><FaCheckCircle /></span>
                                                            <span> {item}</span>
                                                        </li>
                                                    );
                                                })
                                        }
                                        {currentViewMore === yearlybasic[0].planname && isViewMore && yearlybasic[0].featureslist.slice(7).map((item, index) => (
                                            <li key={index}>
                                                <span><FaCheckCircle /></span>
                                                <span> {item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='third-container'>
                                    <span className='plan-viewmore' onClick={() => handleViewmore(yearlybasic)}>
                                        {currentViewMore === yearlybasic[0].planname && isViewMore ? 'view less' : 'view more'}
                                    </span>
                                </div>
                                <div className='fourth-container'>
                                    {yearlybasic[0].purchased == "" ?
                                        // <div className='prduct-button--container'>
                                        //     <div>
                                        //         <button className='commonBtn purchased'><a href="">Free Trial</a></button>
                                        //     </div>
                                        //     <div>
                                        <button className="commonBtn" onClick={() => handleBuyPlan(yearlybasic)}>Buy Plan</button>
                                        //     </div>
                                        // </div>
                                        :
                                        <button className={`commonBtn plan-btn ${handlePlanText(yearlybasic) == "Current Plan" ? "purchased" : ""}`} onClick={() => handleCurrrentPlan(yearlybasic)}>{handlePlanText(yearlybasic)}</button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='plan'>
                            <img src={limitedofferimage} alt="limited period offer" className='discount-offer' />
                            <div className='plan-header blue'>
                                <h2 className='plan-name'>
                                    <span className='premium-badge'><RiAwardFill /></span>
                                    <span className='plan-title'>{yearlystandard[0].planname}</span>
                                </h2>
                                {
                                    yearlystandard[0].previousprice != "" &&
                                    <>
                                        <span className='plan-prices'>&#8377;{yearlystandard[0].previousprice}</span>
                                    </>
                                }
                                <h3 className='plan-price'>&#8377;{yearlystandard[0].yearlyprice}</h3>
                            </div>
                            <div className='plan-body' >
                                <div className='first-container'>
                                    <span className='feature-header'>Features:</span>
                                    <span className='features-title'>(HRM Standard + KMS)</span>
                                </div>
                                <div className='second-container'>
                                    <ul>
                                        {
                                            yearlystandard[0].featureslist.slice(0, 4).map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <span className='core-feature'>Everything in Basic +</span>
                                    <ul>
                                        {
                                            yearlystandard[0].featureslist.slice(4, 7).map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                        {currentViewMore === yearlystandard[0].planname && isViewMore && yearlystandard[0].featureslist.slice(7).map((item, index) => (
                                            <li key={index}>
                                                <span><FaCheckCircle /></span>
                                                <span> {item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='third-container'>
                                    <span className='plan-viewmore' style={{ marginTop: "0rem" }} onClick={() => handleViewmore(yearlystandard)}>
                                        {currentViewMore === yearlystandard[0].planname && isViewMore ? 'view less' : 'view more'}
                                    </span>
                                </div>
                                <div className='fourth-container'>
                                    {yearlystandard[0].purchased == "" ?
                                        // <div className='prduct-button--container'>
                                        //     <div>
                                        //         <button className='commonBtn purchased'><a href="">Free Trial</a></button>
                                        //     </div>
                                        // <div>
                                        <button className="commonBtn" onClick={() => handleBuyPlan(yearlystandard)}>Buy Plan</button>
                                        // </div>
                                        // </div>
                                        : <button className={`commonBtn plan-btn ${handlePlanText(yearlystandard) == "Current Plan" ? "purchased" : ""}`} onClick={() => handleCurrrentPlan(yearlystandard)}>{handlePlanText(yearlystandard)}</button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='plan'>
                            <img src={limitedofferimage} alt="limited period offer" className='discount-offer' />
                            <div className='plan-header blue'>
                                <h2 className='plan-name'>
                                    <span className='premium-badge'><RiAwardFill /></span>
                                    <span className='plan-title'>{yearlypremium[0].planname}</span>
                                </h2>
                                {
                                    yearlypremium[0].previousprice != "" &&
                                    <>
                                        <span className='plan-prices'>&#8377;{yearlypremium[0].previousprice}</span>
                                    </>

                                }
                                <h3 className='plan-price'>&#8377;{yearlypremium[0].yearlyprice}</h3>

                            </div>
                            <div className='plan-body' >
                                <div className='first-container'>
                                    <span className='feature-header'>Features:</span>
                                    <span className='features-title'>(HRM Advanced + KMS+ LMS)</span>
                                </div>
                                <div className='second-container'>
                                    <ul>
                                        {
                                            yearlypremium[0].featureslist.slice(0, 4).map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <span className='core-feature'>Everything in Standard +</span>
                                    <ul>
                                        {
                                            yearlypremium[0].featureslist.slice(4, 7).map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span><FaCheckCircle /></span>
                                                        <span> {item}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                        {currentViewMore === yearlypremium[0].planname && isViewMore && yearlypremium[0].featureslist.slice(7).map((item, index) => (
                                            <li key={index}>
                                                <span><FaCheckCircle /></span>
                                                <span> {item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='third-container'>
                                    <span className='plan-viewmore' style={{ marginTop: "0rem" }} onClick={() => handleViewmore(yearlypremium)}>
                                        {currentViewMore === yearlypremium[0].planname && isViewMore ? 'view less' : 'view more'}
                                    </span>
                                </div>
                                <div className='fourth-container'>
                                    {yearlypremium[0].purchased == "" ?
                                        // <div className='prduct-button--container'>
                                        //     <div>
                                        //         <button className='commonBtn purchased' onClick={() => navigate("/contactus")}><a href="">Free Trial</a></button>
                                        //     </div>
                                        // <div>
                                        <button className="commonBtn" onClick={() => handleBuyPlan(yearlypremium)}>Buy Plan</button>
                                        //     </div>
                                        // </div>
                                        :
                                        <button className={`commonBtn plan-btn ${handlePlanText(yearlypremium) == "Current Plan" ? "purchased" : ""}`} onClick={() => handleCurrrentPlan(yearlypremium)}>{handlePlanText(yearlypremium)}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </>

            }

        </div>
    )
}

export default Plans