import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { notifyError } from "../constants/toastAlerts";
import FrontendLayout from "../Components/Sidebar/FrontendLayout";
import { useSelector } from "react-redux";


const FrontendProtectRoute = ({children, user, redirect="/frontend/login"}) => {
  const isLoggedIn = user;

  if (!isLoggedIn) {
    return <Navigate to={redirect} />;
  }

  return children ? children : <FrontendLayout />;
};

export default FrontendProtectRoute;