import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import brandlogo from "../../assets/Vliv logo.png";
import whitebrandlogo from "../../assets/Vliv logo-white.png"
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { IoIosSunny } from "react-icons/io";
import { TOGGLE_THEME } from '../../redux/actionTypes';
import { IoIosMoon } from "react-icons/io";
import { LuMenu } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import { Avatar, Button, IconButton, Menu, Switch, Tooltip, Typography } from '@mui/material';
import LogoutIcon from "@mui/icons-material/Logout";
import noprofile from "../../assets/No-image.webp";
import { FaHome } from "react-icons/fa";
import { userLogout } from '../../redux/actions/login.action';

function Header() {
    //redux
    const dispatch = useDispatch();

    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        position,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress
    } = useSelector((state) => state.userDataReducer.userData || {});

    const theme = useSelector((state) => state.themeReducer.theme);

    //state
    const [anchorElUser, setAnchorElUser] = useState(false);
    const [menu, setMenu] = useState(false);
    const [profileEl, setProfileEl] = useState(null);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 425);

    const navigate = useNavigate();

    const { token } = useSelector((state) => state.userDataReducer || "");;

    const pages = [
        {
            path: "/features",
            name: "Features",
            // icon: <MdDashboard />,
        },
        {
            path: "/plans",
            name: "Pricing",
            // icon: <MdDashboard />,
        },
        {
            path: "/blogs",
            name: "Knowledge Hub",
            // icon: <MdDashboard />,
        },
        {
            path: "/partnership",
            name: "Partnership",
            // icon: <MdDashboard />,
        },
    ];

    const toggleTheme = () => {
        dispatch({ type: TOGGLE_THEME });
    }

    const handleContactForm = () => {
        navigate("/frontend/login")
    }

    const toggleMenu = () => {
        setMenu(!menu);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCloseDetailsMenu = () => {
        setProfileEl(null);
    };

    const handleHomeClick = () => {
        navigate("/frontend/home")
    }

    const handleProfileEdit = () => {
        handleCloseUserMenu();
        navigate("/frontend/profile");
    }

    const handleLogout = () => {
        dispatch(userLogout(navigate));
    };

    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth <= 425);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    return (
        <>
            <header className={`section-navbar ${theme === "light" ? 'light' : 'dark'}`}>
                <div className={`container header-container`}>
                    <div className='logo-container'>
                        <Link to="/">
                            <img src={theme === "light" ? brandlogo : whitebrandlogo} alt="Vliv brand Logo" width="100" height="auto" />
                        </Link>
                    </div>
                    <nav className={`nav-container `}>
                        {pages.map((page, index) => {
                            // if (page.subRoutes) {
                            //     return (
                            //         <NavbarMenu
                            //             setIsOpen={setIsOpen}
                            //             route={page}
                            //             // showAnimation={showAnimation}
                            //             isOpen={isOpen}
                            //             key={index}
                            //             handleMenuModal={handleMenuModal}
                            //         />
                            //     );
                            // }
                            return (
                                <NavLink
                                    to={page.path}
                                    key={index}
                                    className={`navlink ${theme === "light" ? 'light' : 'dark'}`}
                                    sx={{ my: 2, mx: 1, color: 'black', display: 'flex' }}
                                >
                                    {page.name}
                                </NavLink>
                            )
                        })}
                    </nav>
                    <div className="menu-icon" >
                        <span>
                            {!token && <button className={`commonBtn ${theme == "light" ? "light" : "dark"}`} onClick={handleContactForm}>Sign In / Sign Up</button>}
                            {token && isMobileView && <div className='home-profile--header'>
                                <IconButton onClick={handleOpenUserMenu}>
                                    <Avatar
                                        alt="Profile Image"
                                        src={!clientimage ? noprofile : clientimage}
                                        sx={{
                                            '& img': {
                                                objectFit: 'contain',
                                            },
                                        }}
                                    />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    sx={{
                                        mt: "1rem"
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <div className='user-profile-container'>
                                        <div>
                                            <Typography sx={{ position: "relative" }}>
                                                <img
                                                    src={
                                                        !clientimage ? noprofile : clientimage
                                                    }
                                                    alt="user-profile"
                                                    className='user-profile--Image'
                                                    height={50}
                                                    width={50}
                                                    style={{ objectFit: "contain" }}
                                                    onClick={handleProfileEdit}

                                                />
                                                <span className="header-editIcon" onClick={handleProfileEdit}><MdEdit /></span>
                                            </Typography>
                                        </div>
                                        <div className='profilehome'>
                                            <Tooltip title="Access Vliv" placement="right-start" arrow className='profile-tooltip'
                                                sx={{
                                                    '& .css-1k51tf5-MuiTooltip-tooltip': {
                                                        fontSize: '1.2rem !important', // Increase font size
                                                    }
                                                }}
                                            >
                                                <IconButton onClick={handleHomeClick}>
                                                    <FaHome />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <h3>{username}</h3>
                                        <p>Role: {!userrole ? "--" : userrole}</p>
                                        <p>Profile: {!userprofile ? "--" : userprofile}</p>
                                        <p>Position: {!position ? "--" : position}</p>
                                        <p>{userrole == "masteradmin" ? clientemail : email}</p>
                                        {/* <p>User id: {userid ? userid : "--"}</p> */}
                                        <Button
                                            variant="outlined"
                                            endIcon={<LogoutIcon />}
                                            sx={{ borderRadius: "10px", color: "#0B4A6F", fontSize: "1.2rem", margin: "1rem 0rem" }}
                                            onClick={handleLogout}
                                        >
                                            Logout
                                        </Button>
                                    </div>
                                </Menu>
                            </div>}
                        </span>
                        <span>
                            <Switch
                                checked={theme == "dark"}
                                onChange={toggleTheme}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        </span>
                        <span onClick={toggleMenu} style={{ margin: "0.5rem 0rem 0rem 0rem" }}>
                            <LuMenu />
                        </span>
                    </div>
                    {menu && (
                        <div
                            className={`menu-container ${theme === "light" ? "light" : "dark"
                                }`}
                        >
                            <div>
                                <span className="cross-icon" onClick={toggleMenu}>
                                    <RxCross2 />
                                </span>
                            </div>
                            <div>
                                <ul className={`menu-navbar`}>
                                    {pages.map((item, index) => {
                                        return (
                                            <li >
                                                <NavLink
                                                    to={item.path}
                                                    key={index}
                                                    className={`menu-item ${theme === "light" ? 'light' : 'dark'}`}
                                                >
                                                    {item.name}
                                                </NavLink>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className='button-container'>
                        <span className={`modeicon ${theme === "light" ? 'light' : 'dark'}`} onClick={toggleTheme}>{theme === "light" ? <IoIosMoon /> : <IoIosSunny />} </span>
                        {!token && <button className={`commonBtn ${theme == "light" ? "light" : "dark"}`} onClick={handleContactForm}>Sign In / Sign Up</button>}

                        {token && !isMobileView && <div className='home-profile--header'>
                            <IconButton onClick={handleOpenUserMenu}>
                                <Avatar
                                    alt="Profile Image"
                                    src={!clientimage ? noprofile : clientimage}
                                    sx={{
                                        '& img': {
                                            objectFit: 'contain',
                                        },
                                    }}

                                />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                sx={{
                                    mt: "1rem"
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}

                            >
                                <div className='user-profile-container'>
                                    <div>
                                        <Typography sx={{ position: "relative" }}>
                                            <img
                                                src={
                                                    !clientimage ? noprofile : clientimage
                                                }
                                                alt="user-profile"
                                                className='user-profile--Image'
                                                height={70}
                                                width={70}
                                                onClick={handleProfileEdit}
                                                style={{ objectFit: "contain" }}

                                            />
                                            <span className="header-editIcon" onClick={handleProfileEdit}><MdEdit /></span>
                                        </Typography>
                                    </div>
                                    <div className='profilehome'>
                                        <Tooltip title="Access Vliv" placement="right-start" arrow className='profile-tooltip'
                                            sx={{
                                                '& .css-1k51tf5-MuiTooltip-tooltip': {
                                                    fontSize: '1.5rem !important', // Increase font size
                                                }
                                            }}
                                        >
                                            <IconButton onClick={handleHomeClick}>
                                                <FaHome />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <h3>{username}</h3>
                                    <p>Role: {!userrole ? "--" : userrole}</p>
                                    <p>Profile: {!userprofile ? "--" : userprofile}</p>
                                    <p>Position: {!position ? "--" : position}</p>
                                    <p>{userrole == "masteradmin" ? clientemail : email}</p>
                                    {/* <p>Userid: {userid ? userid : "--"}</p> */}
                                    <Button
                                        variant="outlined"
                                        endIcon={<LogoutIcon />}
                                        sx={{ borderRadius: "10px", color: "#0B4A6F", fontSize: "1.2rem", margin: "1rem 0rem" }}
                                        onClick={handleLogout}
                                    >
                                        Logout
                                    </Button>
                                </div>
                            </Menu>
                        </div>}
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;
