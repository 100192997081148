import { FETCH_USER_DATA } from "../actionTypes";
import Cookies from "js-cookie";

const storedToken = Cookies.get('companytoken');

const userState = {
    token: storedToken,
    userData: {},
};

export const userDataReducer = (state = userState, { type, payload }) => {

    switch (type) {

        case FETCH_USER_DATA: {
            return { ...state, token: payload?.token, userData: payload?.userData };
        }

        default: {
            return state;
        }
    }

};

