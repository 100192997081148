import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Switch,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  TextField,
  TablePagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "js-cookie";
import AddUser from "./AddUser";
import axios from "axios";
import { companyUserGet } from "../../../../constants/api";
import { notifyError } from "../../../../constants/toastAlerts";
import EditUser from "./EditUser";
import { useDispatch, useSelector } from "react-redux";
import { plans } from "../../../../redux/actions/plans.action";
import _ from 'lodash';

const User = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";

  //Redux
  const dispatch = useDispatch();
  const planData = useSelector((state) => state.planReducer.planData);

  useEffect(() => {
    dispatch(plans({ "email": email }));
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [totalUsers, setTotalUsers] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    getData();
  };

  const handleEditDrawerClose = () => {
    setEditDrawerOpen(false);
    setSelectedUser({
      userid: "",
      username: "",
      reportmanager: "",
      email: "",
      userphone: "",
      userrole: "",
      userprofile: "",
      usermodules: [],
      emptype: "",
      position: "",
      level: "",
      otapplicable: "",
      branch: "",
      attendancescheme: "",
      location: "",
      companyemail,
      companyid,
      status: "active",
      password: "",
    });
    getData();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // console.log("page", page);
  // console.log("row per page", rowsPerPage)

  const getData = async () => {
    setLoading(true);
    try {
      let res = await axios.post(companyUserGet, { companyemail, companyid }, { withCredentials: true });

      if (res.data && res.data.data && res.data.data.length > 0) {
        setTotalUsers(res.data.totalusers);
        setData(res.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    }
    setLoading(false);
  };

  //search start
  const handleSearch = async (value) => {
    setLoading(true);
    setSearchKeyword(value);
  };

  const debouncedSearch = useCallback(_.debounce(() => {
    setLoading(false);
  }, 300), []);

  const filteredData = () => {
    if (data.length > 0) {
      debouncedSearch()
      return data?.filter((item) =>
        Object.values(item)?.some(value =>
          value?.toString()?.toLowerCase()?.includes(searchKeyword.toLowerCase().trim())
        )
      );
    }
    else {
      return [];
    }

  }

  const filterData = filteredData();
  // console.log("filterData", filterData)

  useEffect(() => {
    getData();
  }, []);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setEditDrawerOpen(true);
  };

  const handleStatusChange = async (user) => {
    if (user.userrole == "masteradmin") {
      notifyError("Can't Deactivate Master Admin");
      return;
    }

    const updatedStatus = user.status === "active" ? "inactive" : "active";
    try {
      await axios.post("https://vliv.app/user/updatestatus", {
        idarray: [user.id],
        status: updatedStatus,
      });
      getData();
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };

  return (
    <>
      <Box>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Typography variant="h4">INVITE</Typography>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
            flexWrap={"wrap"}
          >
            <TextField
              type="search"
              placeholder="Search..."
              variant="outlined"
              value={searchKeyword}
              onChange={(e) => handleSearch(e.target.value)}
              sx={{
                width: "25rem",
                '& input[type="search"]': {
                  fontSize: "1.5rem",
                  padding: "1rem"
                }
              }}
            />
            <Button
              className="saveBtn text-transform-cap"
              onClick={() => setDrawerOpen(true)}
            >
              Invite
            </Button>
            <Typography variant="h5" className="fs">
              {data.length} / {totalUsers}
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <TableContainer component={Paper} className="fs" >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className='tableheader'>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Reporting Manager</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone No</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Profile</TableCell>
                  <TableCell>Module</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="common-table-body">
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : filterData.length > 0 ? (
                  filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow
                      key={row.id}
                      style={{
                        textDecoration:
                          row.status !== "active" ? "line-through" : "none",
                        opacity: row.status !== "active" ? 0.5 : 1,
                      }}
                    >
                      <TableCell sx={{ p: 0 }}>{row.userid || "--"}</TableCell>
                      <TableCell>{row.username || "--"}</TableCell>
                      <TableCell>{row.reportmanager || "--"}</TableCell>
                      <TableCell>{row.email || "--"}</TableCell>
                      <TableCell>{row.userphone || "--"}</TableCell>
                      <TableCell>{row.userrole || "--"}</TableCell>
                      <TableCell>{row.userprofile || "--"}</TableCell>
                      <TableCell>
                        {row.usermodules.join(", ") || "--"}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEditClick(row)}
                        >
                          <EditIcon />
                        </IconButton>
                        <Switch
                          checked={row.status === "active"}
                          onChange={() => handleStatusChange(row)}
                          color={
                            row.status === "active" ? "success" : "info"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Typography variant="h6" sx={{fontSize:"1.8rem"}}>No Users Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className='commonpagination'
            rowsPerPageOptions={[10, 25, 100, filterData?.length]}
            component="div"
            count={filterData.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <AddUser drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} />
      <EditUser
        editDrawerOpen={editDrawerOpen}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        handleEditDrawerClose={handleEditDrawerClose}
      />
    </>
  );
};

export default User;
