import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import noImage from "../../../assets/No-image.webp";
import { Tab, Tabs } from '@mui/material';
import CustomTabPanel, { a11yProps } from '../../CustomTabPanel/CustomTabPanel';
import Plans from './Plans';
import { plans } from '../../../redux/actions/plans.action';
import earlybird from "../../../assets/earlybird.webp";
import { useNavigate } from 'react-router-dom';
import FreeTrial from '../../Home/Plans/FreeTrial';
import { reloadPage } from '../../../redux/actions/load.action';
import morningImage from "../../../assets/morningImage.webp";
import afternoonImage from "../../../assets/afternoonImage.webp";
import nightImage from "../../../assets/eveningImage.webp";
// import loadingAnimation from "../../../assets/Animation - 1722597592899.gif";
import Loader from '../../../constants/Loader';
import PageLoading from '../../../constants/PageLoading';
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import { vlivBackendApi } from '../../../constants/api';
import Cookies from 'js-cookie';
import { FETCH_LOCATION_DATA } from '../../../redux/actionTypes';

function FrontendHome() {
  const navigate = useNavigate();
  const isProduction = process.env.NODE_ENV === 'production';
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  // Cookies.set("userinfo", JSON.stringify(userData));
  // console.log("userData", userData);
  //redux
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer.theme);
  const loadData = useSelector((state) => state.loadReducer);
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress,
  } = useSelector((state) => state.userDataReducer?.userData || {})

  // const userData = useSelector((state) => state.loginReducer.userData || {})

  const planData = useSelector((state) => state.planReducer.planData);
  const isfreetrial = planData.isfreetrial || false;
  const firstname = username && username.length > 0 && username.split(" ");

  //state
  const [value, setValue] = useState(0);
  const [expireContainer, setExpireContainer] = useState(false)
  const [backgroundImage, setBackgroundImage] = useState(() => {
    const { backgroundImage } = getBackgroundImageAndMessage();
    return backgroundImage;
  });

  const [greetingMessage, setGreetingMessage] = useState(() => {
    const { greetingMessage } = getBackgroundImageAndMessage();
    return greetingMessage;
  });
  const [locationData, setLocationData] = useState({
    city: "",
    location: "",
    country: "",
    state: "",
    ip: "",
  });
  // console.log("locationData", locationData)
  // Function to get the user's location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getAddressFromCoordinates(latitude, longitude);
        },
        (error) => {
          if (error.message == "User denied Geolocation") {
            getIPDetails();
          }
          // console.error("Error fetching geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  //if user has not allowed location get ip
  const getIPDetails = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json")
      const ipAddress = response.data.ip;
      // Set the IP in the credentials
      setLocationData((prevCred) => ({
        ...prevCred,
        ip: ipAddress
      }));
      dispatch({ type: FETCH_LOCATION_DATA, payload: { ip: ipAddress } })
      const getAddressFromIpDetails = await getLocationFromIpDetails(ipAddress);
      const { latitude, longitude } = getAddressFromIpDetails;
      getAddressFromCoordinates(latitude, longitude);
    }
    catch (error) {
      console.log('Error while fetching Ip details', error);
    }
  }

  //get location from ip
  const getLocationFromIpDetails = async (ip) => {
    try {
      // const response = await axios.get(`http://ip-api.com/json/${ip}`);
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      const { city, region, country, org, latitude, longitude } = response.data;
      const device = "Unknown"; // You may need to implement logic to determine device based on IP, this is just a placeholder
      return {
        city,
        regionName: region,
        country,
        device,
        isp: org,
        latitude,
        longitude,
      };
    }
    catch (err) {
      console.log("Error while fetching location details", err)
    }
  }

  // Function to get address from logitude and latitude
  const getAddressFromCoordinates = async (lat, lon) => {
    const API_KEY = "AIzaSyB3Mbhn-OA-Xd_CIQ0MtgzqgpX5Q3-RF54"; // Add your Google Maps API key here
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${API_KEY}`;

    try {
      const response = await axios.get(url);
      const result = response.data.results[0];
      if (result) {
        const addressComponents = result.address_components;

        let city = "";
        let state = "";
        let country = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
        });

        // Update the cred object with the location details
        setLocationData((prevCred) => ({
          ...prevCred,
          location: result.formatted_address, // Full address
          city: city,
          state: state,
          country: country
        }));
        dispatch({
          type: FETCH_LOCATION_DATA, payload: {
            location: result.formatted_address,
            city: city,
            state: state,
            country: country
          }
        })
      } else {
        console.error("No results found for the given coordinates");
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };

  const loginHistory = async () => {
    try {
      const backendData = {
        email: email,
        name: username,
        userid: userid,
        companyid: companyid,
        companyemail: companyemail,
        module: "VLIV",
        ...locationData,
      };
      const res = await axios.post(`${vlivBackendApi}/user/addloginhistory`, backendData);
      if (res.data.message == "Login History created successfully") {
        userData.loginhistoryid = res.data?.data?.id;
        Cookies.set("userinfo", JSON.stringify(userData),
          {
            domain: isProduction ? '.vliv.app' : 'localhost',
            path: '/',
            secure: isProduction,
            sameSite: isProduction ? 'None' : 'Lax',
            expires: 1
          });
        setLocationData({
          city: "",
          location: "",
          country: "",
          state: "",
          ip: "",
        })
        return;
      } else {
        console.log("Error while creating login history record.")
      }
    }
    catch (error) {
      console.log(`Error while creating login history record ${error.response.data.message}`)
    }
  }

  const getPlanColorName = () => {
    const currentplanname = !planData.planname ? "" : planData.planname;
    let planColor;
    switch (currentplanname) {
      case "Standard":
        planColor = "standardplan";
        break;
      case "Premium":
        planColor = "premiumplan";
        break;
      default:
        planColor = "basicplan";
    }

    return planColor;
  }

  useEffect(() => {
    if (locationData.location && !userData.loginhistoryid) {
      loginHistory();
    }
  }, [locationData.location])


  useEffect(() => {
    dispatch(reloadPage(true))
    const timeoutID = setTimeout(() => {
      dispatch(reloadPage(false))
      dispatch(plans({ "email": email }));
    }, 1000)

    const interval = setInterval(() => {
      const { backgroundImage, greetingMessage } =
        getBackgroundImageAndMessage();
      setBackgroundImage(backgroundImage);
      setGreetingMessage(greetingMessage);
    }, 1000 * 60 * 60);

    //for location prompt

    getLocation();
    // if (!locationData.name) {
    //   setLocationData({
    //     ...locationData,
    //     email: email,
    //     name: username,
    //     userid: userid,
    //     companyid: companyid,
    //     companyemail: companyemail,
    //     module: "VLIV",
    //   })
    // }

    return () => {
      clearTimeout(timeoutID);
      clearInterval(interval)
    };
  }, []);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (planData.daysleft <= 0) {
        setExpireContainer(true)
      }
    }, 1000)

    return () => clearTimeout(timeoutID)

  }, [planData.daysleft]);

  function getBackgroundImageAndMessage() {
    const currentHour = new Date().getHours();
    let backgroundImage, greetingMessage;
    if (currentHour >= 12 && currentHour < 18) {
      // Afternoon image and message
      backgroundImage = afternoonImage;
      greetingMessage = "Good Afternoon";
    } else if (currentHour >= 18) {
      // Night image and message
      backgroundImage = nightImage;
      greetingMessage = "Good Evening";
    } else {
      // Morning image and message
      backgroundImage = morningImage;
      greetingMessage = "Good Morning";
    }
    return { backgroundImage, greetingMessage };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFreeTrial = (plan) => {
    dispatch(reloadPage(true));
    setTimeout(() => {
      dispatch(reloadPage(false));
      navigate('/frontend/plan-summary', { state: { selectedPlan: plan, freeTrial: true } });
    }, 1000)
  }

  const handlePlanExpire = () => {
    setExpireContainer(!expireContainer)
  }

  return (
    <div className={`section-home ${theme}`}>
      {planData?.userrole == "masteradmin" && planData?.planname == null && <div className='grid grid-two--cols'>
        <div className='user-profile'>
          <h1 className='hero-heading'>Welcome to VLIV, <span className={`common-heading ${theme == "dark" && "dark"}`}>{firstname?.[0] || "--"}</span></h1>
          <div className='user-details'>
            <div className={`user-image--container ${theme == "light" ? "light" : "dark"}`}>
              <img src={clientimage ? clientimage : noImage} alt="User Image not available" />
            </div>
            <div className='user-info'>
              <h2 className={`user-name ${theme == "light" ? "light" : "dark"}`}>{username}</h2>
              <h3 className={`user-email ${theme == "light" ? "light" : "dark"}`}>{email}</h3>
              <h4 className={`user-companyname ${theme == "light" ? "light" : "dark"}`}>{companyname}</h4>
            </div>
          </div>
        </div>
        <div className='plans-container'
          style={{
            ...(theme === "dark" && { color: "var(--header-link-color)" })
          }}
        >
          <div className={`plans-page ${theme}`}>
            <img src={earlybird} alt="Early bird image not available" className='earlybirdImage' />
            <div className='tabs-section'>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs-section'>
                <Tab label="Monthly" {...a11yProps(0)} className='tab-button' />
                <Tab label="Yearly" {...a11yProps(1)} className='tab-button' />
              </Tabs>
              <CustomTabPanel value={value} index={0} >
                <Plans
                  currentplan="Monthly"
                >
                </Plans>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Plans
                  currentplan="Yearly"
                >
                </Plans>
              </CustomTabPanel>


            </div>
          </div>
          {!isfreetrial && <FreeTrial theme={theme} handleFreeTrial={handleFreeTrial} loadData={loadData} />}
        </div>
      </div>}

      {planData.planname &&
        <div className='home-container'>
          {loadData ?
            <PageLoading />
            : <>
              {expireContainer && <div className='plan-expirenote--container'>
                <p className='moving-text'>Your {planData?.planname} plan validity has expired, Kindly recharge your plan.</p>
                <span onClick={handlePlanExpire}><RxCross2 /></span>
              </div>}
              <div className='home-image--container'>
                <img src={backgroundImage} alt="Home image not available" className='homeImage' />
                <div className='user-details--container'>
                  <h2 className='greeting-text'>{greetingMessage}</h2>
                  <h2 className='username'>Howdy, {username}</h2>
                </div>
              </div>
              <div className='user-profile'>
                <h1 className='hero-heading'>Welcome to VLIV</h1>
                <div className='user-details'>
                  <div className={`user-image--container ${theme == "light" ? "light" : "dark"}`}>
                    <img src={clientimage ? clientimage : noImage} alt="User Image not available" />
                  </div>
                  <div className='user-info'>
                    <h2 className={`user-name ${theme == "light" ? "light" : "dark"}`}>{username}</h2>
                    <h3 className={`user-email ${theme == "light" ? "light" : "dark"}`}>{email}</h3>
                    <h4 className={`user-companyname ${theme == "light" ? "light" : "dark"}`}>{companyname}</h4>
                    <div className='plan-details'>
                      <span className={`user-planname ${getPlanColorName()} ${theme == "light" ? "light" : "dark"}`}>{planData?.planname}</span>
                      <span className={`user-plan-expirydate ${theme == "light" ? "light" : "dark"}`}>Expires on: {planData?.expiredate}</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      }
    </div>
  )
}

export default FrontendHome