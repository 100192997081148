export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SET_THEME = 'SET_THEME';

//login action types
export const LOGIN_SUCCCESS = 'LOGIN_SUCCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';

export const PLANS = 'PLANS';

export const FETCH_LOCATION_DATA = 'FETCH_LOCATION_DATA';