import { FETCH_LOCATION_DATA } from "../actionTypes"

const initialstate = {
    city: "",
    location: "",
    country: "",
    state: "",
    ip: ""
}

export const locationDataReducer = (state = initialstate, { type, payload }) => {
    switch (type) {

        case FETCH_LOCATION_DATA: {
            return { ...state, ...payload }
        }

        default: {
            return state
        }

    }
}