
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import LogoutIcon from "@mui/icons-material/Logout";
// import userProfile from "../../../assets/profile_image.jpg";
// import vslogo from "../../../assets/venturesathi-logo.png";
// import vslogowhite from "../../../assets/vsathiWhitelogo.png";
import { Badge, Switch } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import noprofile from "../../assets/No-image.webp";
import { useDispatch, useSelector } from "react-redux";
import brandlogo from "../../assets/Vliv logo.png";
import whitebrandlogo from "../../assets/Vliv logo-white.png"
import { LOGOUT_SUCCESS, TOGGLE_THEME } from "../../redux/actionTypes";
import SocialMediaDrawer from "../frontend/SocialMedia/SocialMediaDrawer";
import { useEffect, useState } from "react";
import { plans } from "../../redux/actions/plans.action";
import { todayDate } from "../../constants/getDate";
import { MdEdit } from "react-icons/md";
import { userLogout } from "../../redux/actions/login.action";

const others = [
  {
    name: "Notifications",
    icon: <NotificationsIcon />,
    path: "frontend/notification",
  },
];

function Navbar() {
  //redux
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer.theme);
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    position,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress,
  } = useSelector((state) => state.userDataReducer.userData || {})
  const planData = useSelector((state) => state.planReducer.planData);
  const { purchased, expiredate, daysleft } = planData || "";

  //state
  const [anchorElUser, setAnchorElUser] = useState(false);
  const [profileEl, setProfileEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("userData", userData)
  useEffect(() => {
    dispatch(plans({ "email": email }));
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenDetailsMenu = (event) => {
    setProfileEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseDetailsMenu = () => {
    setProfileEl(null);
  };

  const navigateTo = (url) => {
    navigate(url);
  };

  const handleProfileEdit = () => {
    handleCloseUserMenu();
    navigate("/frontend/profile");
  }

  const handleLogout = () => {
    dispatch(userLogout(navigate));
  };

  const toggleTheme = () => {
    dispatch({ type: TOGGLE_THEME });
  }

  // Sahid social media drawer start
  const [openChatDrawer, setOpenChatDrawer] = useState(false);

  const handleOpenChatDrawer = () => {
    setOpenChatDrawer(true);
  };

  // Sahid social media drawer end
  return (
    <header
      className={`main-container ${theme === "light" ? 'light' : 'dark'}`}
    >
      <Container maxWidth="xl" className="header-section">
        <Toolbar
          disableGutters
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: { xs: "40px", md: "64px" }
          }}
        >
          {/* <Typography
            variant="h6"
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
            }}
          > */}
          <Link to="/">
            <img
              src={theme === "light" ? brandlogo : whitebrandlogo}
              alt="Brand Logo not available"
              width={65}
              height={20}
            // onClick={() => navigateTo("/")}
            />
          </Link>
          {/* </Typography> */}
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              // color: 'inherit',
              textDecoration: "none",
            }}
          >
            <img
              src={vslogo}
              alt="vs-logo"
              width={180}
              height={40}
              onClick={() => navigateTo("/")}
            />
          </Typography> */}

          <Box
            sx={{
              display: { xs: "flex", md: "flex" },
              alignItems: "center",
            }}
          >
            {purchased == "yes" && <Typography
              variant="div"
              component="div"
              sx={{
                mr: { xs: "0px", md: 2 },
              }}
              className="planexpiredate"
            >
              <span >{daysleft}</span>
            </Typography>}
            {/* </Typography> */}

            {/* Sahid's social media start */}
            <IconButton onClick={handleOpenChatDrawer} className={`navbaricon ${theme === "light" ? 'light' : 'dark'}`}><MapsUgcIcon /></IconButton>

            <SocialMediaDrawer
              open={openChatDrawer}
              handleClose={() => setOpenChatDrawer(false)}
            />
            {/* Sahid's social media end */}

            <Switch
              checked={theme == "dark"}
              onChange={toggleTheme}
              inputProps={{ "aria-label": "controlled" }}
            />
            <IconButton onClick={handleOpenUserMenu} sx={{ padding: { xs: "2px", md: "8px" } }}>
              <Avatar
                alt="Profile Image"
                src={!clientimage ? noprofile : clientimage}
                sx={{
                  '& img': {
                    objectFit: 'contain',
                  },
                }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              sx={{
                marginTop:"1rem"
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}

            >
              <div
                className='user-profile-container'
              >
                <div>
                  <Typography sx={{ position: "relative" }}>
                    <img
                      src={
                        !clientimage ? noprofile : clientimage
                      }
                      alt="user-profile"
                      className='user-profile--Image'
                      height={80}
                      width={80}
                      onClick={handleProfileEdit}

                    />
                    <span className="header-editIcon" onClick={handleProfileEdit}><MdEdit /></span>
                  </Typography>
                </div>
                <h3>{username}</h3>
                <p>Role: {!userrole ? "--" : userrole}</p>
                <p>Profile: {!userprofile ? "--" : userprofile}</p>
                <p>Position: {!position ? "--" : position}</p>
                <p>{userrole == "masteradmin" ? clientemail : email}</p>
                {/* <p>Userid: {userid ? userid : "--"}</p> */}
                <Button
                  variant="outlined"
                  endIcon={<LogoutIcon />}
                  sx={{ borderRadius: "10px", color: "#0B4A6F", fontSize: "1.2rem", margin: "1rem 0rem" }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            </Menu>
          </Box>
          {/* <Box
            sx={{
              display: { xs: "flex", md: "none", justifyContent: "flex-end" },
              flexGrow: 1,
            }}
          >
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenDetailsMenu} sx={{ p: 0 }}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={profileEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(profileEl)}
              onClose={handleCloseDetailsMenu}
            >
              {others?.map((other, ind) => (
                <MenuItem key={ind}>
                  <Button
                    onClick={() => {
                      navigate(other.path);
                      handleCloseDetailsMenu();
                    }}
                    sx={{
                      color: "black",
                      backgroundColor:
                        location.pathname === other.path
                          ? "azure"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  >
                    {other.icon} &nbsp; {other.name}
                  </Button>
                </MenuItem>
              ))}
              <MenuItem>
                <Switch
                  // checked={checked}
                  // onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />{" "}
                <span>Change Theme</span>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={() => navigate("/profile/details")}
                  sx={{ color: "black" }}
                >
                  <Avatar
                    alt="Profile Image"
                    src={
                      !clientimage ? noprofile : clientimage
                    }
                  />{" "}
                  &nbsp;
                  <span>User Details</span>
                </Button>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Button>
                  <LogoutIcon />
                  <span>Logout</span>
                </Button>
              </MenuItem>
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </header>
  );
}
export default Navbar;
