import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { plans } from '../../../../redux/actions/plans.action';
import brandlogo from "../../../../assets/Vliv-color 1.webp";
import whitebrandlogo from "../../../../assets/Vliv-logo white.webp"
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';
import { notifyError, notifySuccess } from '../../../../constants/toastAlerts';
import { reloadPage } from '../../../../redux/actions/load.action';
import Loader from '../../../../constants/Loader';
import { plansorderId, vlivBackendApi } from '../../../../constants/api';

function PlanCheckout() {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedplans = location.state?.selectedPlan || [];
    const isFreeTrial = location.state?.freeTrial || false;

    //redux
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.themeReducer.theme);
    const loadData = useSelector((state) => state.loadReducer);
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress } = useSelector((state) => state.userDataReducer.userData || {})

    const planData = useSelector((state) => state.planReducer.planData);
    const basic = [planData.basic || {}];
    const premium = [planData.premium || {}];
    const standard = [planData.standard || {}];
    const yearlybasic = [planData.yearlybasic || {}];
    const yearlystandard = [planData.yearlystandard || {}];
    const yearlypremium = [planData.yearlypremium || {}];
    // State
    const [open, setOpen] = useState(false);
    const [currentViewMore, setCurrentViewMore] = useState('');
    const [isViewMore, setIsViewMore] = useState(false);
    const [tandc, setTandC] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(selectedplans);

    // const [subscriptionType, setSubscriptionType] = useState(selectedplans[0].plansubscription);
    const [basicUsers, setBasicUsers] = useState(0);
    const [standardUsers, setStandardUsers] = useState(0);
    const [premiumUsers, setPremiumUsers] = useState(0);
    const [coupondata, setCouponData] = useState([]);
    const [couponcode, setCouponcode] = useState(
        {
            couponcode: "",
            coupondiscount: 0
        }
    );
    const [orderid, setOrderId] = useState(0);

    const fetchCouponData = async () => {
        try {
            const [res1, res2] = await Promise.all([
                axios.post(`${vlivBackendApi}/company/getcoupen`, {}, { withCredentials: true }),
                axios.post(plansorderId, {}, { withCredentials: true })
            ]);
            
            const coupondata = res1.data.length > 0 ? res1.data : [];
            const orderdetails = res2.data && res2.data.orderid;
            setCouponData(coupondata);
            setOrderId(orderdetails)
        }
        catch (error) {
            console.log(`Error while fetch coupon data, ${error}`);
        }
    }

    useEffect(() => {
        dispatch(plans({ "email": email }));
        fetchCouponData();
    }, [email, dispatch]);

    const handleCoupleCodeChange = (e) => {
        const newCouponCode = e.target.value;
        if (newCouponCode.length <= 12) {
            setCouponcode({
                ...couponcode,
                couponcode: newCouponCode
            });
        } else {
            notifyError("You have entered wrong coupon code")
        }
    }

    const handleApplyCode = (coupondata) => {
        setCouponcode({
            couponcode: coupondata.coupencode,
            coupondiscount: coupondata.discount
        });
    }

    // const subTotalAmount = Number(selectedPlan?.planprice || 0)
    //     + Number(selectedPlan?.additionaluserprice || 0)
    //     + Number(selectedPlan?.addonpackprice || 0);

    const subTotalAmount = Number(selectedPlan?.amount || 0)

    const discountAmount = subTotalAmount * (Number(couponcode.coupondiscount) / 100);

    const taxAmount = (subTotalAmount - discountAmount) * (18 / 100);

    const totalAmount = subTotalAmount - discountAmount + taxAmount;

    const getSubTotalAmount = subTotalAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const getDiscountAmount = discountAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const getTaxAmount = taxAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const getTotalAmount = totalAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    // console.log("selectedplan", selectedPlan.additionaluserprice)
    const handlePay = async () => {
        dispatch(reloadPage(true));

        const backendReqBodyData = {
            id: companyid,
            companyid,
            color: selectedPlan?.color,
            planname: selectedPlan?.planname,
            planprice: selectedPlan?.planprice,
            previousprice: selectedPlan?.previousprice,
            users: selectedPlan?.users,
            additionalusercost: selectedPlan?.additionalusercost,
            additionalusers: selectedPlan?.additionalusers,
            additionaluserprice: selectedPlan?.additionaluserprice,
            monthlyprice: selectedPlan?.monthlyprice,
            purchased: selectedPlan?.purchased,
            featureslist: selectedPlan?.featureslist,
            viewmorefeature: selectedPlan?.viewmorefeature,
            yearlyprice: selectedPlan?.yearlyprice,
            plansubscription: isFreeTrial ? "7-days Free Trial" : selectedPlan?.plansubscription,
            discount: selectedPlan?.discount,
            addonpack: selectedPlan?.addonpack,
            purchasedate: selectedPlan?.purchasedate,
            expiredate: selectedPlan?.expiredate,
            companyemail: companyemail,
            basic: planData.basic,
            standard: planData.standard,
            premium: planData.premium,
            yearlybasic: planData.yearlybasic,
            yearlystandard: planData.yearlystandard,
            yearlypremium: planData.yearlypremium,
            isfreetrial: isFreeTrial,
            userid: companyid,
            name: clientname,
            number: "9090909090",
            MID: "MID" + Date.now(),
            transactionid: "T" + Date.now(),
            purchaseaddonpack: selectedPlan?.purchaseaddonpack || [],
            addonpackprice: selectedPlan?.addonpackprice,
            tax: Number(!isFreeTrial ? taxAmount : 0) || 0,
            couponcode: { ...couponcode, couponamount: discountAmount },
            isadditional: !selectedPlan?.isadditional ? false : true,
            extrausers: !selectedPlan?.isadditional ? 0 : selectedPlan?.totaladditionalusers,
            amount: Number(totalAmount),
            // amount: Number(1), 

        }

        // console.log("backendReqBodyData", backendReqBodyData);

        try {
            if (isFreeTrial) {
                const res = await axios.post("/company/freetrialupdate", backendReqBodyData);
                // console.log("res", res);
                if (res.data.message == "Plan updated successfully") {
                    setTimeout(() => {
                        dispatch(reloadPage(false));
                        notifySuccess(res.data.message);
                        navigate("/frontend/home")
                    }, 2000)

                }
                else {
                    setTimeout(() => {
                        dispatch(reloadPage(false));
                        notifySuccess(res.data.message);
                    }, 2000)

                }
            }
            else {
                const res = await axios.post("/company/updateplan", backendReqBodyData);
                if (res.data.message == 'Payment gateway page') {

                    setTimeout(() => {
                        dispatch(reloadPage(false));
                        // notifySuccess(res.data.message);
                        // navigate("/frontend/home")
                        const url = res.data.redirectUrl;

                        if (url) {
                            window.location.href = url; // Redirect the user
                        } else {
                            notifyError("Payment gateway error");
                        }
                    }, 2000)

                }
                else {
                    setTimeout(() => {
                        dispatch(reloadPage(false));
                        notifySuccess(res.data.message);
                    }, 2000)

                }
            }
        }
        catch (error) {
            console.log(`Error in plan summary page ${error}`)
        }
    }

    return (
        <div className={`section-home ${theme} `}>
            <div className={`checkoutHeader ${theme}`}>
                {/* <img
                    src={theme === "light" ? brandlogo : whitebrandlogo}
                    alt="Brand Logo not available"
                    width={65}
                    height={20}
                /> */}
                <span className='checkout-order--details'>
                    <h1 className='checkoutOrderId'>Order ID : {orderid}</h1>
                    <p className='checkoutpayment'>Payment Pending</p>
                </span>
            </div>
            <div className={`grid grid-three--cols plan-checkout ${theme}`}>
                <div className='checkout-section cards-checkout'>
                    <div className=''>
                        <h2 className={`plancheckout-heading`}>Plan Details</h2>
                        <div className={`plancheckout-card ${selectedPlan?.planname}`} >
                            <h2 className='plancheckout-details'>Vliv {selectedPlan.planname}</h2>
                            <p className="plancheckout-type">{isFreeTrial ? "Free Trail" : selectedPlan?.plansubscription}</p>
                        </div>

                        {!selectedPlan?.isadditional &&
                            <div style={{ marginTop: "1rem" }}>
                                <p className={`checkoutAddon`}>{selectedPlan?.purchaseaddonpack.length > 0 ? "Selected Addons" : "No Addons Selected"}</p>
                                <ul className={`addonpackname`}>
                                    {
                                        selectedPlan?.purchaseaddonpack.map((item, index) => {
                                            return (
                                                <li key={index}>{item.id}. {item.addonpackname}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className='checkout-section'>
                    <div className='customer-deatils cards-checkout' >
                        <h2 className={`plancheckout-heading`}>Customer Details</h2>
                        <ul className='checkout-userdetails'>
                            <li>
                                <span className=''>Subscriber Name</span>
                                <span>{username}</span>
                            </li>
                            <li>
                                <span className=''>Mail ID</span>
                                <span>{email}</span>
                            </li>
                            <li>
                                <span className=''>Phone No</span>
                                <span>{userphone}</span>
                            </li>
                        </ul>
                    </div>
                    {!selectedPlan?.isadditional && <div className='couponcode cards-checkout' >
                        <h2 className='plancheckout-details'>Coupon Code</h2>
                        <div className='couponcode-feild'>
                            <input type="text" name="couponcode" onChange={(e) => handleCoupleCodeChange(e)} value={couponcode.couponcode} placeholder='Enter the Code' className='form-feild' />
                            {couponcode.couponcode != "" && <span className='closeicon' onClick={() => setCouponcode({ couponcode: "", coupondiscount: 0 })}><RxCross2 /></span>}
                            <button type='submit' className='commonBtn purchased'>Apply Code</button>
                        </div>
                        <div className='coupon-card--container'>
                            {coupondata.map((item, index) => {
                                return (
                                    <div className='couponcode-card' key={item.id}>
                                        <div className='coupon-logo'>
                                            <img src={item.image} alt="Coupon Logo not available" className='coupon-logo-image' />
                                        </div>
                                        <div className={`coupon-details`}>
                                            <h2>{item.discount}% off</h2>
                                            <p>Code : {item.coupencode}</p>
                                            <span>Valid until {item.expierdate}</span>
                                        </div>
                                        <div>
                                            <button className='commonBtn purchased' onClick={() => handleApplyCode(item)}>
                                                Apply code
                                            </button>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </div>}
                </div>
                <div className='checkout-section'>
                    <div className='checkout-container cards-checkout' >
                        <h2 className={`plancheckout-heading`}>{!selectedPlan?.isadditional ? "Plan Summary" : "Additional User Summary"}</h2>
                        <div>
                            <ul className='calculation-container'>
                                {!selectedPlan?.isadditional ?
                                    <li className=''>
                                        <span>{selectedPlan?.planname}</span>
                                        <span>&#8377;{(selectedPlan?.planprice || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    </li> :
                                    <li className=''>
                                        <span>Per User Cost</span>
                                        <span>&#8377;{(selectedPlan?.additionalusercost || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    </li>
                                }
                                {!selectedPlan?.isadditional ?
                                    <li className=''>
                                        <span>Additional User Cost</span>
                                        <span>&#8377;{selectedPlan?.additionaluserprice}</span>
                                    </li> :
                                    <li className=''>
                                        <span>Selected Additional Users</span>
                                        <span>{selectedPlan?.amount}</span>
                                    </li>
                                }
                                {!isFreeTrial && !selectedPlan?.isadditional && selectedPlan && selectedPlan?.purchaseaddonpack?.map((item, index) => (
                                    <li key={index}>
                                        <span>{item.addonpackname}</span>
                                        <span>&#8377;{(item.addonprice).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    </li>
                                ))}
                                {/* <li>
                            <span>Tax</span>
                            <span>&#8377;{selectedPlan?.tax}</span>
                        </li> */}
                            </ul>
                            <ul className='subtotal-heading--container'>
                                <li className='payment-details-para'>
                                    <span> Sub-Total</span>
                                    <span>&#8377; {getSubTotalAmount}</span>
                                </li>
                                {couponcode.couponcode != "" && <li className='payment-details-para'>
                                    {couponcode.couponcode}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{couponcode.coupondiscount}% OFF
                                    <span>-&#8377; {getDiscountAmount}</span>
                                </li>}
                                <li className='payment-details-para'>
                                    <span>Tax (18%)</span>
                                    <span>&#8377;{getTaxAmount}</span>
                                </li>
                            </ul>
                            <ul className='total-heading--container'>
                                <li className='payment-details-para'>
                                    <span> Total</span>
                                    <span>&#8377; {getTotalAmount}</span>
                                </li>
                            </ul>
                        </div>
                        <div className='tandc'>
                            <input
                                type="checkbox"
                                id="exampleCheckbox"
                                checked={tandc}
                                onChange={(e) => setTandC(e.target.checked)}
                            />
                            <label htmlFor="TandC">Agree to the  <Link to={"/terms-and-condition"}>Terms and Conditions</Link></label>
                        </div>

                        <div className='checkout-button--container'>
                            <div>
                                <button className={`commonBtn ${!tandc ? "commonBtnDisable" : ""}`} onClick={tandc ? handlePay : null}>{loadData ? <Loader /> : !isFreeTrial ? "Pay" : "Start your Free Trial"}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default PlanCheckout