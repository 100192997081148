
import Backdrop from '@mui/material/Backdrop';
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
} from "@mui/material";
import { formattedDate, formattedTime } from '../../../constants/formatedDate';

const style = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "1.5rem"
};

const boxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 8,
  my: 1.5,
};

const childStyle = {
  flex: 1,
};

function LoginHistoryModal({ open, handleClose, rowData }) {
  const [data, setData] = useState({
    modalname: "Login History Details",
    userid: "",
    name: "",
    email: "",
    module: "",
    date: "",
    time: "",
    location: "",
    city: "",
    state: "",
    country: "",
    ipaddress: "",
    accesslocation: "",
    createdAt: "",
    updatedAt: "",
  })

  useEffect(() => {
    if (rowData) {
      setData((prevData) => ({
        ...prevData,
        ...rowData
      }))
    }
  }, [rowData])
  return (
    <div>
      <Modal
        // aria-labelledby="transition-modal-title"
        // aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      // closeAfterTransition
      // slots={{ backdrop: Backdrop }}
      // slotProps={{
      //   backdrop: {
      //     timeout: 500,
      //   },
      // }}
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems={"center"} px={2} py={0.5}>
            <Typography
              color={"#000A14"}
              fontSize={"1.8rem"}
              fontWeight={"500"}
              textAlign={"center"}
            >
              {data.modalname}
            </Typography>

            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Divider />
          <Grid container px={2} sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2, gap: 2, borderRadius:"5rem"}}>
            <Grid item md={5.5} sm={12} xs={12} className="view-left">
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>
                  User Id{" "}
                </p>
                <p className="view-page-value" style={childStyle}>
                  {data.userid}
                </p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>
                  Name{" "}
                </p>
                <p className="view-page-value" style={childStyle}>
                  {data.name}
                </p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>
                  Email{" "}
                </p>
                <p className="view-page-value" style={childStyle}>
                  {data.email}
                </p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>
                  Module{" "}
                </p>
                <p className="view-page-value" style={childStyle}>
                  {data.module?.length > 0 ? data.module.join(" → ") : "--"}
                </p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>
                  Date
                </p>
                <p className="view-page-value" style={childStyle}>
                  {formattedDate(data.updatedAt) || "--"}
                </p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>
                  Login Time
                </p>
                <p className="view-page-value" style={childStyle}>
                  {formattedTime(data.createdAt) || "--"}
                </p>
              </Box>
            </Grid>
            <Grid item md={5.5} sm={12} xs={12} className="view-right">

              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>
                  City :
                </p>
                <p className="view-page-value" style={childStyle}>
                  {data.city}
                </p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>
                  State :
                </p>
                <p className="view-page-value" style={childStyle}>
                  {data.state}
                </p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>Country :</p>
                <p className="view-page-value" style={childStyle}>{data.country}</p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>IP Address :</p>
                <p className="view-page-value" style={childStyle}>{data.ip}</p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>Location Access:</p>
                <p className="view-page-value" style={childStyle}>{!data.ip ? "Yes" : "No"}</p>
              </Box>
              <Box sx={boxStyle}>
                <p className="view-page-title" style={childStyle}>
                  Location :
                </p>
                <p className="view-page-value" style={childStyle}>
                  {data.location}
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}

export default LoginHistoryModal