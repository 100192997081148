import React, { useEffect, useRef, useState } from 'react'
import { commaSeparatedPrice } from '../../../../constants/commaSeparatedPrice';
import axios from 'axios';
import { useSelector } from 'react-redux';
import brandLogo from "../../../../assets/Vliv-color 1.webp";

function PaymentStatusPage({ getTransactionId, containerRef}) {
    const [paymentData, setPaymentData] = useState({});
    // const containerRef = useRef(null);
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress } = useSelector((state) => state.userDataReducer.userData || {});



    const fetchData = async () => {
        try {
            const body = {
                companyid: companyid,
                companyemail: companyemail
            }
            const response = await axios.post('/company/getpaymentdata', body, { withCredentials: true });
            const paymentData = response.data.find((item) => item.transactionId == getTransactionId());
            setPaymentData(paymentData);
        }
        catch (error) {
            console.log("Error in fetching payment status ", error)
        }
    }
    useEffect(() => {
        // const url = new URL(window.location.href);
        // const pathParts = url.pathname.split('/');
        // const transactionid = pathParts.find(part => part.startsWith('transactionid=')).split('=')[1];
        // const merchantid = pathParts.find(part => part.startsWith('merchantid=')).split('=')[1];
        // const date = pathParts.find(part => part.startsWith('date=')).split('=')[1];
        // const amount = pathParts.find(part => part.startsWith('amount=')).split('=')[1];
        // const pay = pathParts.find(part => part.startsWith('pay=')).split('=')[1];
        // setParams({ transactionid, merchantid, date, amount, pay });
        fetchData();
    }, []);

    const getSubTotalAmount = !paymentData?.isadditional
        ? Number(paymentData?.planprice) + Number(paymentData?.additionaluserprice) + Number(paymentData?.addonpackprice)
        : Number(paymentData?.additionalusercost) * Number(paymentData?.extrausers);
    const getTotalAmount = (Number(paymentData?.planprice) + Number(paymentData?.additionaluserprice) + Number(paymentData?.addonpackprice) - Number(paymentData?.coupondetails?.couponamount || 0)) + Number(paymentData?.tax);


    return (
        <div
            style={{ display: "none" }}
        >
            <div ref={containerRef} className='print-section'>
                <div>
                    <img src={brandLogo} alt='Vliv logo not available' width={90} height="auto" />
                    <h2 className='print-heading'>Payment Receipt</h2>
                </div>
                <hr className='row-line' />
                <div className='payment-details--container'>
                    <div className='payment-address--details'>
                        <div>
                            <h2 className='payment-details-heading'>From</h2>
                            <div>
                                <p className='payment-details-para'>Venturesathi Business Services LLP</p>
                                <p className='payment-details-para'>Office no: 220, Second Floor, Forum Galaria, Civil Township</p>
                                <p className='payment-details-para'> Rourkela, Odisha, 769004</p>
                                <p className='payment-details-para'>India</p>
                                <p className='payment-details-para'>PAN : AAVFV9926B</p>
                                <p className='payment-details-para'>GST : 21AAVFV996B1ZV</p>
                            </div>
                        </div>
                        <div>
                            <h2 className='payment-details-heading'>To</h2>
                            <div>
                                <p className='payment-details-para'>{companyname}</p>
                                <p className='payment-details-para'>{address}</p>
                                <p className='payment-details-para'>{address2}</p>
                                <p className='payment-details-para'>{city} {state}, {pincode}</p>
                                <p className='payment-details-para'>{country} - {countrycode}</p>
                                <p className='payment-details-para'>PAN : {pan}</p>
                                <p className='payment-details-para'>GST : {companygst}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className='payment-details-heading'>Details</h2>
                        <ul className='payment-details'>
                            <li className=''>
                                <span>Invoice ID:</span>
                                <span>{paymentData?.invoiceid}</span>
                            </li>
                            <li className=''>
                                <span>Payment Date:</span>
                                <span>{paymentData?.date}</span>
                            </li>
                            <li className=''>
                                <span>Type:</span>
                                <span>{paymentData?.planname}({paymentData?.plansubscription})</span>
                            </li>
                        </ul>

                        <h2 className='payment-details-heading'>Payment Details</h2>
                        <ul className='payment-details'>
                            <li className=''>
                                <span>Transaction ID:</span>
                                <span>{paymentData?.transactionId}</span>
                            </li>
                            <li className=''>
                                <span>Merchant ID:</span>
                                <span>{paymentData?.merchantid}</span>
                            </li>
                            <li className=''>
                                <span>Merchant Transaction ID:</span>
                                <span>{paymentData?.merchanttransactionid}</span>
                            </li>
                            <li className=''>
                                <span>Payment Mode:</span>
                                <span>{paymentData?.type}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <hr className='row-line' />

                <div className='payment-details--container'>
                    <div>
                        <h2 className='payment-details-heading'>Plan</h2>
                        <p className='payment-details-para'> {!paymentData?.isadditional ? paymentData?.planname : "Per User Cost"}</p>
                        <p className='payment-details-para'>{!paymentData?.isadditional ? `Additional User ${paymentData?.additionalusers}` : "Selected Additional Users"}</p>
                        {
                            paymentData?.addonpack?.map((item, index) => {
                                return (
                                    <p className='payment-details-para' key={index}>{item.addonpackname}</p>
                                )
                            })
                        }

                    </div>
                    <div>
                        <h2 className='payment-details-heading'>Amount</h2>
                        <div className='amount-container'>
                            <p className='payment-amount'>&#8377; {!paymentData?.isadditional ? commaSeparatedPrice(paymentData?.planprice) : paymentData?.additionalusercost}</p>
                            {!paymentData?.isadditional ? <p className='payment-amount'>&#8377; {commaSeparatedPrice(paymentData?.additionaluserprice)}</p> :
                                <p className='payment-amount'>{paymentData?.extrausers}</p>}
                            {
                                paymentData?.addonpack?.map((item, index) => {
                                    return (
                                        <p className='payment-amount' key={index}>&#8377; {commaSeparatedPrice(item.addonprice)}</p>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
                <div className='payment-details--container payment-subtotal'>
                    <div>
                        <ul className='subtotal-heading--container'>
                            <li className='payment-details-para'>Sub-Total</li>
                            {paymentData?.coupondetails?.couponamount != 0 && <li className='payment-details-para'>Coupon Code</li>}
                            <li className='payment-details-para'>TAX</li>
                        </ul>
                    </div>
                    <div>
                        <ul >
                            <li className='payment-amount'>&#8377; {commaSeparatedPrice(getSubTotalAmount)}</li>
                            {paymentData?.coupondetails?.couponamount != 0 && <li className='payment-amount'>&#8377; - {commaSeparatedPrice(paymentData?.coupondetails?.couponamount)}</li>}
                            <li className='payment-amount'>&#8377; {commaSeparatedPrice(paymentData?.tax)}</li>
                        </ul>
                    </div>
                </div>

                <hr className='row-line' />
                <div className='payment-details--container'>
                    <div>
                        <h2 className='payment-details-heading'>Total</h2>
                    </div>
                    <div>
                        <h2 className='payment-details-heading bold'>&#8377; {!paymentData?.isadditional ? commaSeparatedPrice(getTotalAmount) : paymentData?.amount}</h2>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default PaymentStatusPage